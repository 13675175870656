const GEPLANT = "GEPLANT";
const ANGEWIESEN = "ANGEWIESEN";
const BESTAETIGT = "BESTAETIGT";
const BEGONNEN = "BEGONNEN";
const EINSTEIGEN = "EINSTEIGEN";
const LOSGEFAHREN = "LOSGEFAHREN";
const ANKUNFTZIEL = "ANKUNFTZIEL";
const AUSSTEIGEN = "AUSSTEIGEN";
const CLEARUP = "CLEARUP";
const BEENDET = "BEENDET";



export const STATUS = {
    GEPLANT,
    ANGEWIESEN,
    BESTAETIGT,
    BEGONNEN,
    EINSTEIGEN,
    LOSGEFAHREN,
    ANKUNFTZIEL,
    AUSSTEIGEN,
    CLEARUP,
    BEENDET
};

const STATUS_GROUP_ORDER = [EINSTEIGEN, LOSGEFAHREN, ANKUNFTZIEL, AUSSTEIGEN, BEGONNEN, BESTAETIGT, ANGEWIESEN, GEPLANT, CLEARUP, BEENDET];
const STATUS_MARKER = [EINSTEIGEN, LOSGEFAHREN, ANKUNFTZIEL, AUSSTEIGEN];

export function statusGroupAndSort(bus_status_list) {
    const status2index = Object.fromEntries(STATUS_GROUP_ORDER.map((s, index) => [s, index]));
    var fn = (o1, o2) => {
        let d = status2index[o1.status] - status2index[o2.status];
        if (d === 0)
            return o1.timestamp > o2.timestamp ? -1 : 1;
        return d
    }
    bus_status_list.sort(fn)
    bus_status_list.forEach((s, i) => {
        s.sort_order = i;
    })
    return bus_status_list
}

function vwpStatus(bus_status, virtual_wbs_players) {
        if (!bus_status || !bus_status.virtual_wbs_player) return;
        let vwp = virtual_wbs_players.find(vwp => vwp.id === bus_status.virtual_wbs_player);
        return vwp;
}

function bus_vm_active(bus_status, virtual_wbs_players) {
    let vwp = vwpStatus(bus_status, virtual_wbs_players);
    return !(!vwp || ["INSTANCIABLE", "CREATED"].includes(vwp.state));

}

export function statusMark(bus_status_list, virtual_wbs_players) {

    bus_status_list.forEach(s => {
        s.marked = STATUS_MARKER.includes(s.status);
        s.vmactive = bus_vm_active(s, virtual_wbs_players);
    });
    return bus_status_list
}

