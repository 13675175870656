<template>
  <v-app>
    <Navbar />
    <v-main class="mx-4 mb-4">
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>



<script>
    import Navbar from './components/Navbar';
    import Footer from './components/Footer';
    import { connect } from './api/frontend-status-ws'


    export default {
        name: 'App',
        components: {
            Navbar,
            Footer
        },
        created() {
          connect();
        },
        methods: {

        }
    };
</script>
<style>
    .BaseDataTableCard {
        height: 355px;
    }
</style>
