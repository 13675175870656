import Vue from 'vue';
import VueRouter from 'vue-router';
import BusStatus from "@/views/BusStatus";
import BusPlan from "@/views/BusPlan";
import FlightIndex from "@/views/FlightIndex";
import FlightDetail from "@/views/FlightDetail";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Bus Status',
        component: BusStatus
    },
    {
        path: '/busplan',
        name: 'Bus Plan',
        component: BusPlan
    },
    {
        path: '/flights',
        name: 'Flight Index',
        component: FlightIndex
    },
    {
        path: '/flight/:id',
        name: 'Flight Detail',
        component: FlightDetail
    },

];

const router = new VueRouter({
    routes
});

export default router;
