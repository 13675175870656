import Vue from 'vue';
import jQuery from 'jquery';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

window.vue = Vue;
window.jquery = jQuery;
window.store = store;
window.app = new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
