<template>
  <nav>
    <v-navigation-drawer
        v-model="drawer"
        class="secondary white--text pt-1"
        app
        clipped
        mobile-breakpoint="800"
        width="200">
      <v-list id="drawer-buttons">
        <v-list-item
            v-for="link in links"
            :key="link.text"
            router
            active-class="primary"
            :to="link.route">
          <v-list-item-action>
            <v-icon class="white--text">
              {{ link.icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text ">
              {{ link.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
        app
        clipped-left
        class="secondary white--text">
      <v-app-bar-nav-icon
          class="white--text"
          @click="drawer = !drawer"/>
      <v-toolbar-title class="text-uppercase grey--text">
        <span class="font-weight-light">Programmatic Proxy | </span>
        <span>DASHBOARD</span>
      </v-toolbar-title>

      <v-spacer/>

      <v-btn
          icon
          title="Link to walkby sys applications"
          @click="openLocation('/login/')">
        <v-icon class="white--text">
          apps
        </v-icon>
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
              icon
              v-on="on">
            <v-icon class="white--text">
              more_vert
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              v-for="(item, index) in moreItems"
              :key="index">
            <v-list-item-title @click="openLocation(item.url)">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </nav>
</template>

<script>

export default {
  name: "Navbar",
  data() {
    return {
      drawer: false,
      links: [
        {text: "Bus status", icon: "directions_bus", route: "/"},
        {text: "Bus plan", icon: "departure_board", route: "/busplan"},
        {text: "Flights", icon: "flight", route: "/flights"},
      ],
      moreItems: [
        {title: 'Logout', url: '/login/logout'},
      ]
    };
  },
  methods: {
    openLocation(url) {
      window.location = url;
    }
  }
};
</script>

<style scoped>

</style>
