let ENDPOINT = ((window.location.protocol === "https:") ? "wss://" : "ws://") + window.location.host + "/programmatic-proxy/ws/fe_status/";

let ws;

export function connect() {
  if (!ws) {
    _connect();
  }
}

function _connect() {
  ws = new WebSocket(ENDPOINT);
  ws.onopen = () => {
    console.log("update websocket opened");
  };
  ws.onmessage = event => {
    console.log("update websocket received: " + event.data);
    let j = JSON.parse(event.data)
    if (j.type === "bus_status") {
      let bus_status = j.bus_status;
      window.store.commit("SET_ONE_BUS_STATUS", bus_status)
    }
    if (j.type === "virtualwbsplayer") {
      let virtualwbsplayer = j.virtualwbsplayer;
      window.store.commit("SET_VIRTUAL_WBS_PLAYER", virtualwbsplayer)
    }
  };

  ws.onclose = event => {
    console.log(
      "update websocket closed (" + event.code + ")"
    );
    setTimeout(() => {
      _connect();
    }, 1000);
  };

  ws.onerror = error => {
    console.log("update websocket error: " + error.message);
    ws.close();
  };
}
