<template>
  <v-footer
    app
    fixed
    clipped-left
    class="secondary">
    <span class="caption white--text mx-3">&copy; 2020 - The WBS Team</span>
  </v-footer>
</template>

<script>
    export default {
        name: "Footer"
    };
</script>

<style scoped>

</style>
