<template>
  <v-container>
    <v-overlay :value="overlay">
      <v-card
    class="mx-auto"
    max-width="960"
    v-if="preview_media"
  >
        <v-app-bar>
          {{ preview_media.title }}
          <v-spacer></v-spacer>
          <v-btn
        icon
        @click="overlay = !overlay"
      >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <img v-if="preview_media.preview_url.endsWith('.png') || preview_media.preview_url.endsWith('.jpg')" style="width: 100%" :src="preview_media.preview_url" />
        <video v-else style="width: 100%" :src="preview_media.preview_url" autoplay muted controls loop></video>
  </v-card>
    </v-overlay>
    <h3 v-if="details && details.flight_info">{{ details.flight_info.timestamp }} | {{ details.flight_info.flight }} | {{ details.flight_info.destination }} {{ details.flight_info.bound === "A" ? "Inbound" : "Outbound" }}</h3>
    <v-simple-table v-if="details">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">

          </th>
          <th class="text-left">
            Flight Playlist
          </th>
          <th :key="bus.id" v-for="bus in details.bus_info" class="text-left">
            Bus {{ bus.bus_seq_no }} (Bus Nr {{ bus.id }})
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="text-left">
            Timestamp
          </th>
          <td>{{ localdatetime(details.timestamp) }}</td>
          <td :key="bus.id" v-for="bus in details.bus_info">{{ localdatetime(bus.timestamp) }}</td>
        </tr>
        <tr>
          <th class="text-left">
            Playlist Position
          </th>
          <td></td>
          <td :key="bus.id" v-for="bus in details.bus_info"></td>
        </tr>
        <tr :key="'playlist_' + row_index" v-for="(row, row_index) in playlistRows">
          <td :key="'playlist_' + col_index" v-for="(col, col_index) in row" :class="{media: !!col.media, valid: col.valid, invalid: !col.valid, header: col.header, content: !col.header}" @click="showVideo(col.media)"> {{ col.content }}</td>
        </tr>
         <tr>
          <th class="text-left">
            Bus Status
          </th>
          <td></td>
          <th :key="bus.id" v-for="bus in details.bus_info">Bus {{ bus.bus_seq_no }} (Bus Nr {{ bus.id }})</th>
        </tr>
        <tr :key="'status_timeline_' + row_index" v-for="(row, row_index) in statusRows">
          <td :key="'status_timeline_' + col_index" v-for="(col, col_index) in row" class="status" :class="{header: col.header, content: !col.header, preparing: col.class === 'preparing', active: col.class === 'active' }"> {{ col.content }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "FlightDetail",
  data: () => ({
    overlay: false,
    preview_media: null
  }),
  computed: {
    ...mapGetters({flightDetail: "FLIGHT_DETAIL", medias: "MEDIAS"}),
    details() {
      return this.flightDetail(this.$route.params.id)
    },
    playlistRows() {
      let rows = [];
      let rowcount = Math.max(this.details.playlist.length, Math.max.apply(Math, this.details.bus_info.map(bi => bi.playlist.length)));
      for (let row=0; row<rowcount; row++) {
        let columns = [];
        columns.push({header: true, valid: true, content: (row+1)+"."});
        let root_entry = this.details.playlist[row];
        if (!root_entry) root_entry = "";
        let root_media = this.medias[root_entry];
        columns.push({header: false, valid: true, media: root_media, content: (root_media ? root_media.title : root_entry) || "" })
        for (let col=0; col<this.details.bus_info.length; col++) {
          let bi = this.details.bus_info[col]
          let entry = bi.playlist[row];
          let media = this.medias[entry];
          columns.push({header: false, valid: entry === root_entry, media, content: (media ? media.title : entry) || ""});
        }
        rows.push(columns);
      }
      return rows;
    },
    statusRows() {
      let rows = [];
      let rowcount = Math.max.apply(Math, this.details.bus_info.map(bi => bi.status_timeline.length))
      rowcount += Math.max(rowcount - 1, 0); //delta rows
      let bus_css_class = {};
      for (let row=0; row<rowcount; row++) {
        let columns = [];
        let delta_row = row%2 === 1;
        columns.push({header: true, valid: true, content: !delta_row ? "timestamp" : "duration"})
        columns.push({header: false, valid: true, content: ""}) //flight playlist column, ignore that
        for (let col=0; col<this.details.bus_info.length; col++) {
          let bi = this.details.bus_info[col];
          let entry = bi.status_timeline[Math.floor(row/2)];
          let next_entry = bi.status_timeline[Math.ceil(row/2)];
          if (delta_row) {
            if (!entry || !next_entry) {
              columns.push({header: false, content: ""});
            } else {
              columns.push({header: false, class: bus_css_class[col], content: this.duration(next_entry.timestamp - entry.timestamp)});
            }
          } else {
            if (!entry) {
              columns.push({header: false, content: ""});
            } else {
              if (entry.status === "BESTAETIGT") {
                bus_css_class[col] = "preparing"
              }
              if (entry.status === "EINSTEIGEN") {
                bus_css_class[col] = "active"
              }
              if (entry.status === "BEENDET") {
                bus_css_class[col] = ""
              }
              columns.push({header: false, class: bus_css_class[col], content: this.localdatetime(entry.timestamp) + " " + entry.status});
            }
          }
        }
        rows.push(columns);
      }
      return rows
    }
  },
  created() {
    moment.locale(navigator.language);
    this.updateHomerIds(this.details)
    Promise.all([
      this.getFlightDetail({ flight_playlist_id: this.$route.params.id }),
    ]).finally(() => {
      this.init();
    })
        .catch(err => {
          console.log(err);
        });
  },
  methods: {
    showVideo(media) {
      if (!media) return;
      this.overlay = true;
      this.preview_media = media;
    },
    statusClass(flight) {
      return flight.marked ? "dt-row-marked" : "";
    },
    localdatetime(ts) {
        if (ts <= 0.0) return "Unknown";
        let d = new Date(ts*1000); //value is seconds since epoch, JS expects ms since epoch
        let m = moment(d)
        return m.format("L LT")
    },
    localtime(ts) {
        if (ts <= 0.0) return "Unknown";
        let d = new Date(ts*1000); //value is seconds since epoch, JS expects ms since epoch
        let m = moment(d)
        return m.format("LT")
    },
    duration(delta_s) {
        return moment.utc(moment.duration(delta_s, "seconds").asMilliseconds()).format("HH:mm:ss")
    },
    init() {

    },
    ...mapActions({
      getFlightDetail: "GET_FLIGHT_DETAIL",
      getMissingMedias: "GET_MISSING_MEDIAS"
    }),
    updateHomerIds(details) {
      if (!details) return;
      let homer_ids = [...details.playlist];
      for (const bi of details.bus_info) {
        homer_ids.push(...bi.playlist)
      }
      homer_ids = [...new Set(homer_ids)];
      this.getMissingMedias({homer_ids})
    }
  },
  watch: {
    details(newDetails) {
      this.updateHomerIds(newDetails);
    }
  }
}
</script>

<style scoped>
  td.content.valid {
    background-color: #afe8c4;
  }
  td.content.invalid {
    background-color: #e8afaf;
  }
  td.header {
    font-weight: bold;
  }
  td.status.preparing {
    background-color: #fff5a2;
  }
  td.status.active {
    background-color: #afe8c4;
  }
  td.content.media:hover {
    background-color: #96c2a4;
    cursor: pointer;
  }
</style>