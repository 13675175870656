import axios from "axios";

let ENDPOINT = "/programmatic-proxy/api/";
let env = process.env.NODE_ENV || "development";
if (env === "development") {
    ENDPOINT = "/programmatic-proxy/api/";
}

export function baseApi() {
    return axios.create({
        baseURL: ENDPOINT,
        withCredentials: false,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        timeout: 60000
    });
}

export default {
    getBusPlanEndpoint(){
        return ENDPOINT + "busstatusupdaterequest/?format=datatables";
    },
    getBusStatus() {
        return baseApi().get("/busstatus/?format=json");
    },
    getFlightIndex() {
        return baseApi().get("/flightindex/?format=json");
    },
    getFlightDetail(flight_playlist_id) {
        return baseApi().get(`/flightindex/${flight_playlist_id}/?format=json`);
    },
    getVirtualWbsPlayers() {
        return baseApi().get("/virtualwbsplayer/?format=json");
    },
    getBusPlan() {
        return baseApi().get("/busstatusupdaterequest/");
    },
    getMedia(homer_ids) {
        let qs = "?h=" + homer_ids.join("&h=");
        return baseApi().get("/media/" + qs);
    },
};







