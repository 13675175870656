var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.busStatus,"item-class":_vm.statusClass,"footer-props":{
        'items-per-page-options': [10, 50, 100]
      },"items-per-page":100,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"group-by":"vmactive","group-desc":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.direction",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.direction === "D" ? "Outbound" : "Inbound")+" ")]}},{key:"item.timestamp",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.vmactive ? _vm.localtime(item.timestamp) : _vm.localdatetime(item.timestamp))+" ")]}},{key:"item.schedule",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.vmactive ? _vm.localtime(item.schedule) : _vm.localdatetime(item.schedule))+" ")]}},{key:"item.virtual_wbs_player",fn:function(ref){
      var item = ref.item;
return [_c('VmStatus',{attrs:{"status":item}})]}},{key:"group.header",fn:function(ref){
      var headers = ref.headers;
      var group = ref.group;
      var isOpen = ref.isOpen;
      var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":headers.length}},[_c('v-icon',{ref:'vm_'+(group ? 'active' : 'inactive'),on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus'))]),_vm._v(" "+_vm._s(group ? "Active" : "Inactive")+" ")],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }