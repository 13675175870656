import Vue from 'vue';
import Vuex from 'vuex';
import Api from '../api/programmatic-proxy';
import {statusGroupAndSort, statusMark} from '../util/busstatus';

Vue.use(Vuex);

const state = {
    bus_status: [],
    bus_plan: [],
    flight_index: [],
    flight_details: {},
    bus_plan_filters: [],
    virtual_wbs_players: [],
    medias: {}
};
const getters = {
    BUS_STATUS: state => state.bus_status,
    BUS_PLAN: state => state.bus_plan,
    FLIGHT_INDEX: state => state.flight_index,
    FLIGHT_DETAIL: state => (flight_playlist_id) => state.flight_details[flight_playlist_id],
    BUS_PLAN_FILTERS: state => state.bus_plan_filters,
    VIRTUAL_WBS_PLAYERS: state => state.virtual_wbs_players,
    VIRTUAL_WBS_PLAYER_FOR_BUS_STATUS: state => (bus_status_id) => {
        let bs = state.bus_status.find(bs => bs.bus_id === bus_status_id)
        if (!bs || !bs.virtual_wbs_player) return;
        let vwp = state.virtual_wbs_players.find(vwp => vwp.id === bs.virtual_wbs_player);
        return vwp;
    },
    MEDIAS: state => state.medias
};
const actions = {
    GET_BUS_STATUS: async context => {
        let response = await Api.getBusStatus();
        let bus_status = response.data["results"];
        context.commit("SET_BUS_STATUS", bus_status);
    },
    GET_VIRTUAL_WBS_PLAYERS: async context => {
        let response = await Api.getVirtualWbsPlayers();
        let virtual_wbs_players = response.data["results"];
        context.commit("SET_VIRTUAL_WBS_PLAYERS", virtual_wbs_players);
    },
    GET_BUS_PLAN: async context => {
        let response = await Api.getBusPlan();
        let bus_plan = response.data["results"];
        context.commit("SET_BUS_PLAN", bus_plan);
    },
    GET_FLIGHT_INDEX: async context => {
        let response = await Api.getFlightIndex();
        let flight_index = response.data["results"];
        context.commit("SET_FLIGHT_INDEX", flight_index);
    },
    GET_FLIGHT_DETAIL: async (context, payload) => {
        let response = await Api.getFlightDetail(payload.flight_playlist_id);
        let flight_detail = response.data;
        context.commit("SET_FLIGHT_DETAIL", flight_detail);
    },
    GET_MISSING_MEDIAS: async (context, payload) => {
        let homer_ids = payload.homer_ids;
        let missing_homer_ids = homer_ids.filter(hid => !(hid in context.state.medias));
        if (missing_homer_ids.length === 0)
            return;
        let response = await Api.getMedia(missing_homer_ids);
        for (const media of response.data) {
            context.commit("SET_MEDIA", media)
        }
    }
};
const mutations = {
    SET_BUS_STATUS: (state, payload) => {
        let bus_status_list = statusGroupAndSort(payload);
        bus_status_list = statusMark(bus_status_list, state.virtual_wbs_players)
        state.bus_status = bus_status_list;
    },
    SET_VIRTUAL_WBS_PLAYERS: (state, payload) => {
        state.virtual_wbs_players = payload;
        let new_status = statusGroupAndSort(state.bus_status);
        new_status = statusMark(new_status, state.virtual_wbs_players);
        state.bus_status = new_status;
    },
    SET_VIRTUAL_WBS_PLAYER: (state, payload) => {
        state.virtual_wbs_players = state.virtual_wbs_players.map(vip => {
            if (vip.id === payload.id) return payload;
            return vip;
        });
        let new_status = statusGroupAndSort(state.bus_status);
        new_status = statusMark(new_status, state.virtual_wbs_players);
        state.bus_status = new_status;
    },
    SET_ONE_BUS_STATUS: (state, bus_status) => {
        let new_status = state.bus_status.filter(bs => bs.bus_id !== bus_status.bus_id);
        new_status.push(bus_status)
        new_status = statusGroupAndSort(new_status);
        new_status = statusMark(new_status, state.virtual_wbs_players);
        state.bus_status = new_status;

    },
    SET_BUS_PLAN: (state, payload) => {
        state.bus_plan = payload;
    },
    SET_FLIGHT_INDEX: (state, payload) => {
        state.flight_index = payload;
    },
    SET_FLIGHT_DETAIL: (state, payload) => {
        let obj = {};
        obj[payload.id] = payload;
        state.flight_details = { ...state.flight_details, ...obj };
    },
    SET_MEDIA: (state, payload) => {
        state.medias[payload.homer_id] = payload;
        state.medias = {...state.medias};
    }
};
export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
});
