<template>
<v-container>
    <v-data-table
        :headers="headers"
        :items="flightIndex"
        :item-class="statusClass"
        :footer-props="{
          'items-per-page-options': [25]
        }"
        :items-per-page="25"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="elevation-1"
        @click:row="gotoFlightDetails">
      <template v-slot:item.direction="{ item }">
        {{ item.bound === "D" ? "Outbound" : "Inbound" }}
      </template>
      <template v-slot:item.timestamp="{ item }">
        {{ localdatetime(item.timestamp) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "FlightIndex",
  data: () => ({
    headers: [
      {text: "Created", value: "timestamp"},
      {text: "Flight", value: "flight"},
      {text: "Dest./Origin", value: "destination"},
      {text: "Job/Direction", value: "direction"},
      {text: "Bus #", value: "bus_count"},
      {text: "Playlist", value: "playlist_length"}
    ],
    sortBy: 'timestamp',
    sortDesc: true,
    reloadInterval: null
  }),
  computed: {
    ...mapGetters({flightIndex: "FLIGHT_INDEX"}),
  },
  created() {
    moment.locale(navigator.language);
    Promise.all([
      this.getFlightIndex(),
    ]).finally(() => {
      this.init();
    })
        .catch(err => {
          console.log(err);
        });

  },
  beforeDestroy() {
      if (this.reloadInterval) {
        clearInterval(this.reloadInterval);
        this.reloadInterval = null;
      }
  },
  methods: {
    statusClass(flight) {
      return flight.status ? "dt-row-valid" : "dt-row-invalid";
    },
    localdatetime(ts) {
        if (ts <= 0.0) return "Unknown";
        let d = new Date(ts*1000); //value is seconds since epoch, JS expects ms since epoch
        let m = moment(d)
        return m.format("L LT")
    },
    localtime(ts) {
        if (ts <= 0.0) return "Unknown";
        let d = new Date(ts*1000); //value is seconds since epoch, JS expects ms since epoch
        let m = moment(d)
        return m.format("LT")
    },
    init() {
      this.reloadInterval = setInterval(this.getFlightIndex, 120000)

    },
    gotoFlightDetails(item) {
      this.$router.push({ name: 'Flight Detail', params: { id: item.id } })
    },
    ...mapActions({
      getFlightIndex: "GET_FLIGHT_INDEX"
    })
  }
}
</script>

<style>
  .dt-row-valid {
    background-color: #afe8c4;
  }
  .dt-row-invalid {
    background-color: #e8afaf;
  }
</style>