<template>
<div>
  <span v-if="hasVm">
  {{ vm.state }} ({{ vm.name }}) {{ extra_info }}
  </span>
  <span v-else>
    NO VM
  </span>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "VmStatus",
  props: {
    status: Object
  },
  computed: {
    ...mapGetters({vwpStatus: "VIRTUAL_WBS_PLAYER_FOR_BUS_STATUS"}),
    vm() {
      if (!this.status.virtual_wbs_player)
        return;
      let vm_status = this.vwpStatus(this.status.bus_id);
      if (!vm_status)
        return;
      return vm_status;
    },
    hasVm() {
      return !!this.vm;
    }
  },
  created() {
    this.intv = setInterval(this.generateExtraInfo, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intv);
  },
  data() {
    return {
      extra_info: "",
      intv: null
    }
  },
  methods: {
    secondsToRepr(seconds) {
      return parseInt(seconds/60) + ":" + (seconds%60).toString().padStart(2, "0");
    },
    generateExtraInfo() {
      if (this.hasVm) {
        if (this.vm.state === "VM_GATHERING_LOOP") {
          let start = this.vm.runtime_state.loop_detection_start;
          if (!start) return;
          start = parseInt(start);
          let now = parseInt((new Date()).valueOf()/1000)
          let delta_seconds = now - start;
          this.extra_info = this.secondsToRepr(delta_seconds);
          return;
        }
        if (this.vm.state === "VM_LOOP_GATHERED") {
          let loop = this.vm.runtime_state.loop;
          if (!loop) return;
          this.extra_info = `${loop.length} items`
          return;
        }
        if (this.vm.state === "VM_PREPLAY") {
          let start = this.vm.runtime_state.preplay_start;
          let loop_detection_duration = this.vm.runtime_state.loop_detection_duration;
          if (!start || !loop_detection_duration) return;
          loop_detection_duration = parseInt(loop_detection_duration);
          start = parseInt(start);
          let now = parseInt((new Date()).valueOf()/1000)
          let delta_seconds = now - start;
          this.extra_info = this.secondsToRepr(delta_seconds) + "/" + this.secondsToRepr(loop_detection_duration);
          return;
        }
        if (this.vm.state === "VM_PLAYING") {
          let start = this.vm.runtime_state.playback_start;
          let loop_detection_duration = this.vm.runtime_state.loop_detection_duration;
          if (!start || !loop_detection_duration) return;
          loop_detection_duration = parseInt(loop_detection_duration);
          start = parseInt(start);
          let now = parseInt((new Date()).valueOf()/1000)
          let delta_seconds = (now - start) + loop_detection_duration;
          this.extra_info = this.secondsToRepr(delta_seconds);
          return;
        }
      }
      this.extra_info = "";
    }
  }
}
</script>

<style scoped>

</style>