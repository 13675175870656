<template>
  <div class="container">
    <v-app>
      <v-container fluid>
        <v-row align="center">
          <!-- <v-col
            class="d-flex"
            cols="12"
            sm="2">
            <v-select
              v-model="searchFilterSetting.selectedStatus"
              append-icon="filter"
              :items="items_status"
              label="STATUS"
              solo
              clearable
              @change="columnStatusSearch()" />
          </v-col>

          <v-col
            class="d-flex"
            cols="12"
            sm="2">
            <v-select
              v-model="searchFilterSetting.selectedFlight"
              :items="items_flights"
              label="FLIGHT"
              solo
              clearable
              @change="columnFlightSearch()" />
          </v-col> -->

          <v-col class="d-flex">
            <form
              class="d-flex formContainer"
              @submit.stop.prevent="doQuickSearch">
              <div class="d-flex mb-6 flexContainer">
                <v-text-field
                  v-model="quickSearch"
                  clearable
                  label="Quick Search"
                  hint="Search Term"
                  @click:clear="resetSearch()" />
                <div class="input-group-append">
                  <button
                    type="submit"
                    class="btn btn-outline-secondary btnContainer">
                    <i class="mdi mdi-magnify" /> Go
                  </button>
                </div>
              </div>
            </form>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col>
            <div class="busplanTableWrapper">
              <vdtnet-table
                id="table_id"
                ref="table"
                :vue="vue"
                :jquery="jquery"
                :fields="fields"
                :opts="options">
                <template v-slot:playlist="ctx">
                  <ul>
                    <li :key="video" v-for="video in ctx.data">{{ video }}</li>
                  </ul>
                </template>
              </vdtnet-table>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import VdtnetTable from '../components/VdtnetTable';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt/css/responsive.dataTables.css';
import 'datatables.net-buttons';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net-select';
import 'datatables.net-select-dt/css/select.dataTables.css';
import Cookie from 'js-cookie';
import {mapActions, mapGetters} from "vuex";
import Api from "@/api/programmatic-proxy";


export default {
  name: 'BusPlayDataTable',
  components: {VdtnetTable},

  data: () => ({
    searchFilterSetting: {"selectedStatus": "", "selectedFlight": ""},
    quickSearch: '',
    vue: window.vue,
    jquery: window.jquery,
    options: {
      ajax: {
        //url: "/programmatic-proxy/api/?format=datatables",
        url: Api.getBusPlanEndpoint(),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-csrftoken': Cookie.get('csrftoken')
        },
        dataSrc: (json) => {
          return json.data;
        }
      },
      sortBy: 'id',
      sortDesc: true,
      order: [[ 16, "desc" ]],
      /*eslint-disable */
      dom: "Btr<'row vdtnet-footer'<'col-sm-12 col-md-6'i><'col-sm-12 col-md-6'pl>>",
      /*eslint-enable */
      responsive: true,
      processing: true,
      searching: true,
      searchDelay: 1500,
      destroy: true,
      ordering: true,
      columnSearch: false,
      lengthChange: true,
      serverSide: true,
      fixedHeader: true,
      saveState: true,
      pagingType: 'simple',
      lengthMenu: [5, 15, 50, 100],
      pageLength: 100,
      language: {
        processing: '<img src="loader.gif" />',
        paginate: {
          previous: '<i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-left theme--light"></i>',
          next: '<i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-right theme--light"></i>',
        }
      }
    },
    fields: {
      bus_status: {label: 'Bus Nr.', sortable: false, searchable: false},
      status: {label: 'Status', sortable: true, searchable: true,},
      destination: {label: 'Dest/Orig', sortable: false, searchable: false},
      bound: {label: 'Bound', sortable: false, searchable: false},
      flight: {label: 'Flight', sortable: true, searchable: true},
      bus_count: {label: 'Bus Count', sortable: false, searchable: false},
      bus_seq_no: {label: 'Bus Seq No', sortable: false, searchable: false},
      start_location: {label: 'Start Location', sortable: false, searchable: false},
      end_location: {label: 'End Location', sortable: false, searchable: false},
      est_start_time: {label: 'Est Start Time', sortable: false, searchable: false},
      est_end_time: {label: 'Est End Time', sortable: false, searchable: false},
      act_start_time: {label: 'Act Start Time', sortable: false, searchable: false},
      act_end_time: {label: 'Act End Time', sortable: false, searchable: false},
      flight_id: {label: 'Flight Id', sortable: false, searchable: false},
      playlist: {label: 'Playliste', sortable: false, searchable: false},
      timestamp: {label: 'Time Stamp', sortable: false, searchable: false},
      id: {label: 'Log Id', sortable: true, searchable: false}
    },
    items_status: ["BEENDET", "CLEARUP", "AUSSTEIGEN", "ANKUNFTZIEL", "LOSGEFAHREN", "EINSTEIGEN", "BEGONNEN", "BESTAETIGT", "ANGEWIESEN", "GEPLANT"],
    items_flights: ["LH 1234", "LH 5678", "1", '6'],

  }),
    computed: {
    ...mapGetters({busPlan: "BUS_PLAN"}),
  },
  created() {
      this.getBusPlan();
  },
  methods: {
    ...mapActions({
      getBusPlan: "GET_BUS_PLAN",
    }),
    resetSearch() {
      console.log("resetSearch");
      this.$refs.table.search('');
    },
    doQuickSearch() {
      console.log("doQuickSearch");
      this.$refs.table.search(this.quickSearch);
    },
    columnStatusSearch(){
      console.log("columnSearch - selected: " + this.searchFilterSetting.selectedStatus);
      let searchTerm = this.searchFilterSetting.selectedStatus ? this.searchFilterSetting.selectedStatus : '';
      this.$refs.table.searchColumn(searchTerm, 2);
    },
    columnFlightSearch(){
      console.log("columnSearch - selected: " + this.searchFilterSetting.selectedFlight);
      let searchTerm = this.searchFilterSetting.selectedFlight ? this.searchFilterSetting.selectedFlight : '';
      this.$refs.table.searchColumn(searchTerm, 6);
    },
  },
}
;
</script>
<style lang="scss">
.busplanTableWrapper {
  font-size: 11px;
}

.inputSearch {
  padding: 10px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 3px;
  outline: none;
}

.flexContainer {
  justify-content: flex-end;
}

.btnContainer {
  padding: 10px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 3px;
  outline: none;
}

.formContainer {
  margin-left: auto;
}

#rectangle {
  width: 200px;
  height: 100px;
  background: red;
}
</style>