<template>
  <v-container>
    <v-data-table
        :headers="headers"
        :items="busStatus"
        :item-class="statusClass"
        :footer-props="{
          'items-per-page-options': [10, 50, 100]
        }"
        :items-per-page="100"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        group-by="vmactive"
        group-desc
        class="elevation-1">
      <template v-slot:item.direction="{ item }">
        {{ item.direction === "D" ? "Outbound" : "Inbound" }}
      </template>
      <template v-slot:item.timestamp="{ item }">
        {{ item.vmactive ? localtime(item.timestamp) : localdatetime(item.timestamp) }}
      </template>
       <template v-slot:item.schedule="{ item }">
        {{ item.vmactive ? localtime(item.schedule) : localdatetime(item.schedule) }}
      </template>
      <template v-slot:item.virtual_wbs_player="{ item }">
        <VmStatus :status="item"></VmStatus>
      </template>
      <template v-slot:group.header="{ headers, group, isOpen, toggle }">
        <th :colspan="headers.length">
          <v-icon @click="toggle" :ref="'vm_'+(group ? 'active' : 'inactive')">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
          {{ group ? "Active" : "Inactive" }}
        </th>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

import {mapGetters, mapActions} from 'vuex';
import VmStatus from '../components/VmStatus'
import moment from 'moment'

export default {
  components: {VmStatus},
  data: () => ({
    headers: [
      {text: "Bus Nr.", value: "bus_id"},
      {text: "Status", value: "status"},
      {text: "Dest./Origin", value: "destination"},
      {text: "Job/Direction", value: "direction"},
      {text: "Flight", value: "flight"},
      {text: "Scheduled", value: "schedule"},
      {text: "Start Location", value: "start_location"},
      {text: "End Location", value: "end_location"},
      {text: "Last Update", value: "timestamp"},
      {text: "VM Status", value: "virtual_wbs_player"},
    ],
    sortBy: 'sort_order',
    sortDesc: false,
  }),
  computed: {
    ...mapGetters({busStatus: "BUS_STATUS", vwpStatus: "VIRTUAL_WBS_PLAYER_FOR_BUS_STATUS"}),
  },
  created() {
    moment.locale(navigator.language);
    Promise.all([
      this.getBusStatus(),
      this.getVirtualWbsPlayers()
    ]).finally(() => {
      this.init();
    })
        .catch(err => {
          console.log(err);
        });
  },
  methods: {
      statusClass(status) {
        return status.marked ? "dt-row-marked" : "";
    },
    localdatetime(ts) {
        if (ts <= 0.0) return "Unknown";
        let d = new Date(ts*1000); //value is seconds since epoch, JS expects ms since epoch
        let m = moment(d)
        return m.format("L LT")
    },
    localtime(ts) {
        if (ts <= 0.0) return "Unknown";
        let d = new Date(ts*1000); //value is seconds since epoch, JS expects ms since epoch
        let m = moment(d)
        return m.format("LT")
    },
    init() {
      console.log("init called!!!");
      if (this.$refs['vm_inactive']) {
        this.$refs['vm_inactive'].$el.click();
      }
    },
    ...mapActions({
      getBusStatus: "GET_BUS_STATUS",
      getVirtualWbsPlayers: "GET_VIRTUAL_WBS_PLAYERS"
    })
  }
};
</script>
<style>
  .dt-row-marked {
    background-color: #afe8c4;
  }
</style>